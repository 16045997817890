.App {
	text-align: center;
	display: flex;
	font-size: calc(10px + 2vmin);
	color: #3C3633;
	background-color: #E0CCBE;
}

.App-header {
	background: #E0CCBE;
	display: inline;
	width: 10%;
	max-width: 10%;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #747264;
	padding-top: 0rem;
	height: 100vh;
	position: sticky;
	position: -webkit-sticky; /* Safari */
	top: 0;
}

a {
	color: #3C3633;
}

h5 {
	margin: 0.5rem 0;
	font-size: 1.15rem;
	color: #3C3633;
	/* top: 0; */
	font-weight: 100;
}

.title {
	font-family: "Amatic SC", cursive;
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 2rem;
}

.maryland {
	position: sticky;
}

.header {
	top: 1vh;
	position: sticky;
}

li > button {
	cursor: pointer;
	margin: 0.25rem 0;
	color: #3C3633;
	background-color: #EEEDEB;
	font-family: "roboto";
	font-size: 1.25rem;
	font-style: bold;
	border-radius: 30%;
	border-style: none;
}

li > button:hover {
	background-color: #747264;
}

/* .MuiBox-root {
	width: calc(90vw - 6px)
} */

.imagebox {
	margin: 3px;
	width: calc(90vw - 6px);
	gap: 6px 6px;
}

.imagebox:hover > li {
	/* opacity: .7; */
	filter: grayscale(35%) blur(2px);
	transition: 0.1s ease-in-out;
}

.imagebox:hover li:hover {
	/* opacity: 1; */
	filter: grayscale(0) blur(0);
	transition: 0.1s ease-in-out;
}

.img:hover {
	/* opacity: 0.9; */
	filter: grayscale(0) blur(0);
	/* box-shadow: 3px 1.5px 1.5px rgb(73, 85, 107); */
	transition: 0.1s ease-in-out;
}

.img {
	cursor: pointer;
	/* border-style: solid;
	border-radius: 3px;
	border-color: #e89173; */
	margin: 3px;
	transform: scale(1);
	transition: 1s ease-in-out;
	/* z-index: 0; */
	z-index: 20;
}

.purchase-link {
	font-size: 1.25rem;
	color: #3C3633;
	position: sticky;
	top: 95%;
}

.categories {
	padding-left: 0;
	margin: 1rem 0;
	color: #E0CCBE;
}
.category {
	font-size: 1.1rem;
	list-style: none;
	text-align: center;
	cursor: pointer;
}

.categories-header {
	margin-bottom: 0;
	font-size: 1.5rem;
}

.focused-div {
	min-width: 80vw;
	max-height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	color: #EEEDEB;
}

.focused {
	max-height: 100vh;
	cursor: auto;
	/* margin: 1rem 0; */
}

.info-div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #3C3633;
	width: 10%;
	height: 100vh;
}

.info-div > div > div {
	margin: 1rem;
}

.link-div {
	margin-top: auto;
	padding-bottom: 1rem;
}

@media screen and (max-width: 1000px) {
	.focused-div {
		flex-direction: column;
		height: auto;
		min-width: none;
		width: 80vw;
	}
	.focused {
		max-height: 80vh;
		width: calc(100% - 6px);
		border: #EEEDEB;
		border-width: 3px;
		border-style: solid;
	}
	.info-div {
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: flex-start;
	}
	.link-div {
		margin-top: 0;
	}
	.author-selected {
		display: none;
	}
	.location-selected {
		display: none;
	}
	.App-header {
		width: 20vw;
	}
	.header > .title {
		font-size: 1.25rem;
	}
	.header > h5 {
		font-size: 0.75rem;
	}
	li > button {
		font-size: 0.75rem;
		margin: 0px 1px;
	}
	.categories-header {
		font-size: 0.75rem;
	}
	.maryland {
		width: 100%;
		height: auto;
		position: sticky;
		text-align: left;
	}
	.purchase-link {
		font-size: 1rem;
		margin-bottom: 1rem;
	}
	.imagebox {
		width: calc(80vw - 16px);
		padding: 3px;
	}
}
